body {
    font-family: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
    background: #fff;
    color: #1c1e21;
    direction: ltr;
    line-height: 1.34;
    margin: 0;
    padding: 0;
    unicode-bidi: embed;
}

.bg-primary, .bg-danger {
    background-color: #f02849 !important;
}

.page-item.active {
    background-color: #f02849 !important;
    border-radius: .3125rem;
}
.text-danger {
    color: #f02849 !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #fe696a;
    background-color: rgba(0,0,0,0);
    border-color: #fe696a;
}

.kh-font {
    font-family: 'Hanuman', Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif !important;
}

.line-height-2 {
    line-height: 2rem !important;
}

.line-height-1 {
    line-height: 12rem !important;
}


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
}

input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: .35 !important;
    /* Firefox */
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: .35 !important;
}

input::-ms-input-placeholder {
    /* Microsoft Edge */
    opacity: .35 !important;
}

.pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.toast {
    border-left: 4px solid #f02849 !important;
    border-radius: 5px !important;
    z-index: 1089 !important;
}


/* input[type="file"] {
    cursor: pointer !important;
    background-color: blue;
} */

.trucate2line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-telegram {
    background-color: rgb(155,216,252,0.20) !important;
    color: rgb(55 174 243) !important;
}
.btn-telegram:hover {
    background-color: rgb(55 174 243) !important;
    color: white !important;
}

.hoverBorder:hover {
    border-color: rgba(254,105,106,0.35) !important;
}

/* Add Product */

.addProduct .list-group-item {
    border: 0 !important;
    padding: none;
}

.removeFile:hover {
    background-color: red !important;
}

.product-gallery-thumblist-item {
    height: auto !important;
}

div.textAreaSey[contenteditable="true"]:focus,
.textAreaSey span[contenteditable="true"]:focus {
    /* border: 1px solid #e3e9ef !important; */
    outline: none !important;
}